




















































































































































































































































































































// 支付结果跳转页面(支付成功/失败)
import { Context } from '@nuxt/types';
import Vue from 'vue';
import _ from 'lodash';
import qs from 'qs';
import moment from 'moment';
import { ResponseCode } from '~/util/constant';
import {
  ageRangeOption,
  userTypeOption,
  defaultAirTicketSearch,
  defaultAirTicketObj,
} from './helper';
import HotelSearch from '~/components/Hotel/Booking.vue';
import HotelItem from '~/components/Hotel/HotelItem.vue';
import { getCodeByName, getNameByCode } from '~/components/Hotel/city-data';

let timer: any = null;
export default Vue.extend<any, any, any>({
  name: 'airTicketResult',
  layout: 'defaultVisa',
  components: {
    HotelSearch,
    HotelItem,
  },
  filters: {
    handleDate(e: any) {
      
      return e ? moment(e).format('MM月DD日') : '';
    },
    handleWeek(e: any) {
      let num: any = moment(e).format('E');
      var week = ['一', '二', '三', '四', '五', '六', '日'];
      return e ? `周${week[num - 1]}` : '';
    },
  },
  asyncData(ctx: Context) {
    const { status } = ctx.params;
    const { id } = ctx.query;
    return {
      status,
      id,
    };
  },
  data() {
    return {
      status: '',
      mode: 'visa',
      seconds: 10,
      detail: {},
      contactInfo: {
        contactName: '',
        contactPhone: '',
      },
      // travelerInfoList: [
      //   {
      //     certNo: '',
      //     certType: '0',
      //     name: '',
      //     birthday: '',
      //     outUserId: '',
      //     phone: '',
      //     type: 'ADULT',
      //   },
      // ],
      searchInfo: JSON.parse(JSON.stringify(defaultAirTicketSearch)),
      flightInfo: JSON.parse(JSON.stringify(defaultAirTicketObj)),
      cabinInfo: JSON.parse(
        JSON.stringify(defaultAirTicketObj.flightCabinInfoList[0])
      ),
      order: {
        pageNum: 1,
        pageSize: 10,
        id: 0,
        depAirport: '',
        arrAirport: '',
        depCity: '',
        arrCity: '',
        flightNo: '',
        airlineCompany: '',
        departureTime: '',
        arrTime: '',
        orderNo: '',
        flightThirdOrderNo: '',
        flightThirdOrderSubNo: '',
        thirdOrderNo: '',
        refundNo: '',
        thirdRefundNo: null,
        buyChannel: '',
        amount: 0,
        payAmount: 0,
        preferentialAmount: 0,
        deductionAmount: 0,
        refundAmount: 0,
        payType: 0,
        status: 0,
        thirdStatus: 0,
        invoiceStatus: 0,
        channel: 0,
        crtTime: '',
        payTime: '',
        refundTime: null,
        creater: '',
        thirdUserId: '0',
        contact: '',
        phone: '',
        operator: null,
        reqOrderParam: '',
        flightTraveler: '',
        travelerInfoList: [],
        flightInfo: null,
        thirdOrderInfo: '',
        thirdRefundOrderInfo: '',
        autoRefund: 0,
        refundRemark: '',
        manageRefundError: null,
        flightCreateOrderRequest: null,
        flightRefundApplyRequest: null,
        verifyCode: null,
        verifyPhoneNumber: null,
        reasonType: null,
        sessionId: null,
        displayRefundMoney: null,
      },
      hotelList: [],
    };
  },
  computed: {
    hotelQuery(): string {
      const query = {
        adult: 1,
        checkin: moment().format('YYYY-MM-DD'),
        checkout: moment().add(3, 'days').format('YYYY-MM-DD'),
        children: 0,
        display: this.order.arrCity,
        cityCode: getCodeByName(this.order.arrCity),
        crn: 1,
        optionName: '',
        starlist: '4,5',
      };

      return qs.stringify(query);
    },
    icon() {
      if (this.status === 'success') {
        return require('~/assets/images/success.png');
      } else {
        return require('~/assets/images/error.png');
      }
    },
    desc() {
      if (this.status === 'success') {
        return `支付成功`;
      } else {
        return '非常抱歉，支付失败。请您重新下单或联系客服处理。';
      }
    },
  },
  created() {
    const { periodical, id, out_trade_no } = this.$route.query;
    // this.getAirTicketInfo();
    this.getOrderInfo(out_trade_no);
    // 酒店
    this.fetchHotel();
  },
  beforeDestroy() {
    if (timer) {
      clearInterval(timer);
    }
  },
  methods: {
    moment,
    // 获取订单信息
    async getOrderInfo(out_trade_no: string) {
      const res: any = await this.$axios.post(
        `/api/flightOrderApi/getByOrderNo`,
        {
          orderNo: out_trade_no,
        }
      );
      if (res.code === '1') {
        let result = res.data;
        result['travelerInfoList'] = JSON.parse(result.flightTraveler);
        this.order = result;
      }
    },
    /**
     * 获取选中的机票的信息
     * @returns {any}
     */
    getAirTicketInfo() {
      // travelerInfoList
      this.travelerInfoList = this.$store.state.airTicket.travelerInfoList;
      let searchInfo = this.$store.state.airTicket.searchInfo;
      for (let i in this.searchInfo) {
        if (searchInfo[i]) this.searchInfo[i] = searchInfo[i];
      }
      let flightInfo = this.$store.state.airTicket.flightInfo;
      for (let i in this.flightInfo) {
        if (flightInfo[i]) this.flightInfo[i] = flightInfo[i];
      }
      let cabinInfo = this.$store.state.airTicket.cabinInfo;
      for (let i in this.cabinInfo) {
        if (cabinInfo[i]) this.cabinInfo[i] = cabinInfo[i];
      }
    },
    // 获取展会详情
    async fetchDetail(id: string | number) {
      const res: any = await this.$axios.get(`/api/exhibition/${id}`);
      if (res.code === ResponseCode.SUCCESS) {
        this.detail = res.data;
      }
    },
    goVisa() {
      this.$router.push({
        path: '/visa',
      });
    },
    goHotel() {
      // this.$router.push({
      //   path: '/hotel/',
      // });
      window.open('/hotel');
    },
    async fetchHotel() {
      // const { displayCode, keyword, checkTime, adultNum, starList } = this.queryObj
      // let [checkin, checkout] = checkTime;
      // checkin = moment(checkin).format('YYYY-MM-DD');
      // checkout = moment(checkout).format('YYYY-MM-DD');

      const hotelListParams = {
        checkInDate: moment().format('YYYY-MM-DD'),
        checkOutDate: moment().add(3, 'days').format('YYYY-MM-DD'),
        adultNum: 1,
        cityCode: getCodeByName(this.order.arrCity),
        // keyWords: this.optionName,
        hotelStar: '4,5',
        pageNo: 1,
        pageSize: 6,
        sortCode: 0, // 0：默认 1：低价优先 2：高价优先 3：距离优先 4：好评优先
      };

      const res = await this.$axios.post(
        '/api/aliTripApi/hotelList',
        hotelListParams
      );
      if (_.get(res, 'code') === ResponseCode.SUCCESS) {
        this.hotelList = _.get(res, 'data.body.module.items', []);
      }
    },
  },
  head(): Record<string, any> {
    return {
      title: `${this.status === 'success' ? '预订成功' : '预订失败'}-聚展`,
      meta: [
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
});
