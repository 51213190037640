



































































































import Vue from 'vue';
import { ElForm } from 'element-ui/types/form';
import { scaleEnum } from '~/pages/booth/helper';
import { ResponseCode } from '~/util/constant';
import { message } from '~/util/common';

export default Vue.extend({
  name: 'ApplyBoothForm',
  props: {
    title: {
      type: String,
      default: '展位申请',
    },
    type: {
      type: String,
      default: 'booth',
    },
    submitText: {
      type: String,
      default: '提交申请',
    },
    fairId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      scaleEnum,
      isLoading: false,
      ruleForm: {
        companyName: '',
        linkman: '',
        remark: '',
        email: '',
        boothType: undefined,
        linkmanPhoneNumber: '',
        content: '',
        scale: undefined,
        scale1: undefined,
        peopleNum: undefined,
      },
      rules: {
        companyName: [
          { required: true, message: '公司名称不能为空', trigger: 'blur' },
        ],
        linkman: [
          { required: true, message: '联系人不能为空', trigger: 'blur' },
        ],
        // email: [
        //   { required: true, message: '邮箱不能为空', trigger: 'blur' },
        //   { type: 'email', message: '请输入正确的邮箱', trigger: 'blur' },
        //   // { validator: checkEmail, trigger: 'blur' },
        // ],
        boothType: [
          { required: true, message: '价格不能为空', trigger: 'blur' },
        ],
        linkmanPhoneNumber: [
          { required: true, message: '联系电话不能为空', trigger: 'blur' },
          { type: 'number', message: '只能输入数字', trigger: 'blur' },
          // { validator: checkTel, trigger: 'blur' },
        ],
        content: [
          { required: true, message: '展品内容不能为空', trigger: 'blur' },
        ],
        scale: [
          { required: true, message: '展位面积不能为空', trigger: 'blur' },
        ],
        scale1: [
          { required: true, message: '展位面积不能为空', trigger: 'blur' },
          {
            type: 'number',
            min: 1,
            message: '请输入合理的展位面积',
            trigger: 'change',
          },
        ],
        peopleNum: [
          { required: true, message: '人数不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    submitForm(formName: string) {
      const $form = this.$refs[formName] as ElForm;
      $form.validate((valid) => {
        if (valid) {
          this.isLoading = true;
          this.handleApplyBooth();
        } else {
          return false;
        }
      });
    },
    async handleApplyBooth() {
      const res: any = await this.$axios.post('/api/exhibition/applyBooth', {
        ...this.ruleForm,
        exhibitionId: this.fairId,
      });
      this.isLoading = false;
      if (res.code === ResponseCode.SUCCESS) {
        const $form = this.$refs.rule as ElForm;
        $form?.resetFields();
        await message.call(this, {
          delay: 3000,
          type: 'success',
          title: '展位申请成功',
          msg: '专业客服将在1-2个工作日内电话与您联系。',
        });
        this.$message.success('申请成功.');
      }
    },
  },
});
